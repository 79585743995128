import { ActionType, DeviceHelper, ImageHelper, IMediaModel } from "@nf/common";
import React, { useEffect, useState } from "react";
import styles from "../HeroBanner.module.scss";
import dynamic from "next/dynamic";
import { useBreakpoints } from "../../../Responsive";
import cx from "classnames";
import { ActionLink } from "../../../ActionLink/ActionLink";
import { MediaButton } from "components/MediaButton";
import { LinkWithLocale } from "components/LinkWithLocale";
import { useTranslation } from "react-i18next";

const SectionContainer = dynamic(
  import("../../CustomComponents/SectionContainer")
);

type THeroBannerSingleItem = {
  element: IMediaModel;
  index: number;
};

type THeroBannerItem = {
  mediaItem: IMediaModel;
  className?: string;
  parentIndex: number;
};

const FULLHEIGHT = "100vh";

export enum MediaItemName {
  HOME = "Home",
  DANSK_FILMSKAT = "DanskFilmskat",
  KIDS = "Kids",
}

const HeroBannerItem: React.FC<THeroBannerItem> = ({
  mediaItem,
  parentIndex,
}) => {
  const { t } = useTranslation("translations");
  const highlightsImageUrl = ImageHelper.getHighlightsImageUrl(
    mediaItem?.Images
  );
  const [height, setHeight] = useState(FULLHEIGHT);
  const [width, setWidth] = useState("100%");
  const { isXS, isMD } = useBreakpoints();
  const isPhone = DeviceHelper.isPhone();

  useEffect(() => {
    if (isXS || isMD) {
      setHeight(Math.round(window.innerWidth * 0.75) + "px");
    } else {
      setHeight(FULLHEIGHT);
    }
    window && setWidth(window.innerWidth + "px");
  }, [isXS, isMD]);

  const isOdd = parentIndex % 2 === 1;

  const hasLink =
    mediaItem.Action?.ActionType === ActionType.OpenScreen &&
    !!mediaItem.Action?.ScreenId;

  const isItemWithButton =
    !isPhone &&
    (mediaItem.Name === MediaItemName.DANSK_FILMSKAT ||
      mediaItem.Name === MediaItemName.HOME ||
      mediaItem.Name === MediaItemName.KIDS);

  return (
    <ActionLink
      mediaItem={mediaItem}
      hasLink={hasLink}
      isItemWithButton={isItemWithButton}
    >
      <div
        className={styles.imageContainer}
        style={{
          backgroundImage: `url(${highlightsImageUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height,
          width,
        }}
      >
        <div
          className={cx(styles.textContainer, {
            [styles.left]: !isOdd,
            [styles.right]: isOdd,
          })}
        >
          <div className={styles.title}>{mediaItem.Title}</div>
          <div className={styles.description}>{mediaItem.Description}</div>
          {hasLink && isItemWithButton && (
            <LinkWithLocale href={`/${mediaItem.Action?.ScreenId}`}>
              <MediaButton variant="plain" className={styles.browseButton}>
                {t("web_start_browse_button", "")}
              </MediaButton>
            </LinkWithLocale>
          )}
        </div>
      </div>
    </ActionLink>
  );
};

export const HeroBannerSingleItem: React.FC<THeroBannerSingleItem> = ({
  element,
  index,
}) => {
  return (
    <SectionContainer className={styles.container} autoHeight>
      <HeroBannerItem mediaItem={element} parentIndex={index} />
    </SectionContainer>
  );
};

export default HeroBannerSingleItem;
