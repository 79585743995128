import { IMediaModel, ScreenType, slugify } from "@nf/common";
import { LinkWithLocale } from "../LinkWithLocale";
import React from "react";
import { ROUTES } from "@nf/constants";

type TActionLink = {
  mediaItem: IMediaModel;
  children: React.ReactNode;
  hasLink: boolean;
  isItemWithButton: boolean;
};

export const ActionLink: React.FC<TActionLink> = ({
  mediaItem,
  children,
  hasLink,
  isItemWithButton,
}) => {
  if (!hasLink || isItemWithButton) {
    return <>{children}</>;
  }

  const isMovieDetails =
    mediaItem.Action?.ScreenTypeCode === ScreenType.MovieDetails;

  const link = isMovieDetails
    ? [
        ROUTES.MOVIE_DETAILS_SCREEN,
        mediaItem.Title ? slugify(mediaItem.Title) : mediaItem.Id,
        mediaItem.Action?.ScreenId,
      ].join("/")
    : `/${mediaItem.Action?.ScreenId}`;

  return (
    <LinkWithLocale href={link}>
      <a>{children}</a>
    </LinkWithLocale>
  );
};
